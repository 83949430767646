@import url('https://rsms.me/inter/inter.css');
html {
  font-size:18px;
  --folded_w: 20px;
  --feed_max_w: 700px;
  @media only screen and (hover: none) and (pointer: coarse){
    font-size:2vh;
    --folded_w: 4vw;
    --feed_max_w: 100%;
    }
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}



input {
  all: unset;
}
textarea {
  all: unset;
}
button {
  all: unset;
}
main {
  all: unset;
}
.temp_layout {
  display: flex;
  flex-direction: row;
  gap: 50px;
  --txt1: 1rem;
  --txt2: 0.9rem;
  --txt3: 0.876rem;
}

.temp_layout1 {
  width: 30%;
}

.temp_layout2 {
  width: 100%;
  max-width: var(--feed_max_w);
}

.temp_layout3 {
  width: 30%;
}

.vstack {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.hstack {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 5px;
}

.vstack_wide {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.round_border {
  border-radius: 0.7vh;
}

.content_description {
  font: var(--txt1) 'Inter';
  font-weight: 400;
  color: var(--g1);
}

.detail_view {
  background: #fff;
  padding: 0.7vh;
  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .left {
    text-align: left;
    vertical-align: top;
    width: 7.5rem;
    min-width: 7.5rem;
    max-width: 7.5rem;
    font: var(--txt1) 'Inter';
    font-weight: 500;
  }
  .right {
    font: var(--txt1) 'Inter';
    font-weight: 400;
    line-break: anywhere;
  }
}

.connection_maker {
  .hide {
    visibility: hidden;
    display: none;
  }
  .show_me {
    visibility: show;
    display: flex;
  }
  .folded_o {
    display:flex;
    justify-content: center;
    width: var(--folded_w);
    max-width: var(--folded_w);
    min-width: var(--folded_w);
    font: var(--txt1) 'Inter';
    padding: 5px;
    box-sizing: border-box;
    line-height: 1;
    cursor: default;
    border-radius: 0.7vh;
    &:hover {
      font-weight: 500;
    }
  }
  .t_lhs_o {
    background: var(--t2);
    color: var(--t1);
    &:hover {
      background: var(--t2);
    }
  }
  .f_lhs_o {
    background: var(--f2);
    color: var(--f1);
    &:hover {
      background: var(--f2);
    }
  }
  .b_lhs_o {
    background: var(--b2);
    color: var(--b1);
    &:hover {
      background: var(--b2);
    }
  }
  .selector {
    background: var(--g2);
  }
}

.page_selector {
  display: flex;
  flex-direction: row;
  gap: 2px;
  border-radius: 0.7vh;
  overflow: hidden;
  .l_most {
    border-radius: 0.7vh 0px 0px 0.7vh;
  }
  .r_most {
    border-radius: 0px 0.7vh 0.7vh 0px;
  }
  .page_select_button {
    font: var(--txt1) 'Inter';
    width: 100%;
    border: 1px solid #fff;
    color: var(--g1);
    background: #fff;
    text-align: center;
    &:hover {
      border: 1px solid #000;
    }
    &:active {
      color: #000;
      border: 1px solid #000;
      font-weight: 500;
    }
  }
  .selected {
    color: #000;
    border: 1px solid #000;
    font-weight: 500;
  }
}

.button_set {
  font: var(--txt1) 'Inter';
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  .interaction_button {
    width: 6.25rem;
    border: 1px solid #fff;
    color: var(--g1);
    border-radius: 0.7vh;
    background: #fff;
    text-align: center;
    &:hover {
      border: 1px solid var(--g2);
    }
    &:active {
      //border: 1px solid #000;
      color: #000;
    }
  }
}

.argument{
  
  display: flex;
  flex-grow: 1;
}

.opinion {
  display: flex;
  flex-grow: 1;
  .hide_me {
    visibility: hidden;
    display: none;
  }

  .show_me {
    visibility: show;
    display: flex;
  }
  .folded {
    display:flex;
    justify-content: center;
    width: var(--folded_w);
    max-width: var(--folded_w);
    min-width: var(--folded_w);
    font: var(--txt1) 'Inter';
    padding: 5px 0px 5px 0px;
    box-sizing: border-box;
    line-height: 1;
    cursor: default;
    &:hover {
      font-weight: 500;
    }
  }
  .rhs_folded {
    background: #fff;
  }
  .t_lhs {
    background: var(--t2);
    color: var(--t1);
  }
  .f_lhs {
    background: var(--f2);
    color: var(--f1);
  }
  .b_lhs {
    background: var(--b2);
    color: var(--b1);
  }
}

.proposition {
  display: flex;
  flex-direction: column;
  gap: 5px;
  //width: 100%;
  flex-grow: 1;
  padding: 0.7vh;
  .display_name {
    font: var(--txt2)'Inter';
    font-weight: 600;
    color: #000;
    &:hover {
      text-decoration: underline;
    }
  }
  .secondary_txt {
    font: var(--txt2) 'Inter';
    font-weight: 300;
    color: var(--g1);
  }
  .op5_txt {
    font: var(--txt2) 'Inter';
    font-weight: 400;
  }
  .id_button {
    font: var(--txt2) 'Inter';
    font-weight: 300;
    color: var(--g1);
    &:hover {
      text-decoration: underline;
    }
  }
  .watching{
    font: var(--txt2) 'Inter';
    font-weight: 500;
    color: var(--i1);
  }
  .prop_button {
    text-decoration: underline;
    &:hover {
      font-weight: 500;
    }
  }
  .primary_txt {
    font: var(--txt1) 'Inter';
    font-weight: 400;
    color: #000;
  }
  .info {
    line-height: 1;
    gap: 5px;
  }
  .info_1{
    white-space: nowrap;
  }
  .info_2{
    white-space: nowrap;
    float: right;
  }
  .info_3{
    white-space: nowrap;
  }
  .opinion_adder_info {
    line-height: 1;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    white-space:normal;
  }
  .expand_area {
    padding: 2px;
    margin: -2px;
    border-radius: 0.7vh;
  }
  .write_tag_here {
    border: solid 1px var(--g3);
    border-radius: 0.7vh;
    font: var(--txt2) 'Inter';
    font-weight: 400;
    padding: 0px 0px 0px 0.7vh;
    color: var(--g1);
    display: flex;
    flex-grow: 1;
    min-width: 0rem;
  }
  .write_src_here {
    border: solid 1px var(--g3);
    border-radius: 0.7vh;
    font: var(--txt2) 'Inter';
    font-weight: 400;
    padding: 0px 0px 0px 0.7vh;
    color: var(--g1);
    display: flex;
    min-width: 0rem;
    width: 25%;
  }
  .write_prop_here {
    border: solid 1px var(--g2);
    border-radius: 0.7vh;
    font: var(--txt1) 'Inter';
    font-weight: 400;
    padding: 0px 0.7vh 0px 0.7vh;
    display: flex;
    flex-grow: 1;
  }
  .t_button {
    font: var(--txt2) 'Inter';
    font-weight: 400;
    color: var(--t1);
    &:hover {
      background: var(--t3);
    }
  }
  .f_button {
    font: var(--txt2) 'Inter';
    font-weight: 400;
    color: var(--f1);
    &:hover {
      background: var(--f3);
    }
  }
  .b_button {
    font: var(--txt2) 'Inter';
    font-weight: 400;
    color: var(--b1);
    &:hover {
      background: var(--b3);
    }
  }
  .t_bg {
    background: var(--t3);
  }
  .f_bg {
    background: var(--f3);
  }
  .b_bg {
    background: var(--b3);
  }
  .t_txt {
    color: var(--t1);
  }
  .f_txt {
    color: var(--f1);
  }
  .b_txt {
    color: var(--b1);
  }
}

.use_tool_tip {
  position: relative;
  display: inline-block;
  .tool_tip {
    position: absolute;
    white-space: nowrap;
    z-index: 1;
    background-color: #fff;
    border: solid 1px var(--g2);
    border-radius: 0.7vh;
    padding: 4px;
    display: none;
    visibility: hidden;
    font: var(--txt2) 'Inter';
    line-height: 1;
    font-weight: 400;
  }
  &:hover {
    .tool_tip {
      display: inline;
      visibility: visible;
    }
  }
  .position_1 {
    bottom: -170%;
    right: -0.7vh;;
  }
}

body {
  --bg: #e8e5e3;

  --g1: #85878a;
  --g2: #aaaabb;
  --g3: #ddddee;
  --g4: #eeeef7;

  --t1: #160df2;
  --t2: #b3d4ff;
  --t3: #d1e5ff;
  --t4: #e7f0fd;

  --f1: #eb0046;
  --f2: #ff8eb1;
  --f3: #ffc7e3;
  --f4: #ffe5f2;

  --b1: #ff5714;
  --b2: #ffaf8f;
  --b3: #f7d9ba;
  --b4: #f7e5d4;

  --i1: #c70fff;
  --i2: #e7b3ff;
  --i3: #efccff;
  --i4: #ecd9f5;


  background: var(--bg);
}
